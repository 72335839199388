<template>
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md4 v-if="status == 'SETTLED'" class="text-center">
        <h1>
          <v-icon style="font-size: 64px" class="primary--text">mdi-check-decagram</v-icon>
        </h1>
        <h4 style="text-transform: capitalize; text-align: center" class="mt-10">
          Booking Confirmed
        </h4>
        <p class="" style="color: #fff; text-align: center" >
          Your booking has been confirmed. You'll
          recieve the booking confirmation on email shortly...
        </p>
  
        <v-divider></v-divider>
       
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "../../config";
  export default {
    data() {
      return {
        status: "",
        order: {},
      };
    },
    mounted() {
      
    },
    methods: {
      home(){
        this.$router.push('/')
      },
     
    },
  };
  </script>